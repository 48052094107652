<template>
  <CustomerTableBase
    ref="table_base"
    :auto_search="auto_search"
    v-on:update_customer="onUpdateCustomer"
  >
    <template v-slot:header>
      <v-row class="px-4">
        <v-col cols="12" lg="1" class="px-1">
          <v-text-field
            v-model="searchInfo.full_name"
            hide-details
            outlined
            placeholder="姓名"
            class="font-size-input placeholder-er border border-radius-md"
            @keydown.enter="searchCustomers"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2" class="px-1">
          <v-text-field
            v-model="searchInfo.address"
            hide-details
            outlined
            :placeholder="$t('Address')"
            class="font-size-input placeholder-er border border-radius-md"
            @keydown.enter="searchCustomers"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="1" class="px-1">
          <v-text-field
            v-model="searchInfo.id_full"
            hide-details
            outlined
            :placeholder="$t('UID')"
            class="font-size-input placeholder-er border border-radius-md"
            @keydown.enter="searchCustomers"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2" class="px-1">
          <v-text-field
            v-model="searchInfo.company"
            hide-details
            outlined
            :placeholder="$t('Company')"
            class="font-size-input placeholder-er border border-radius-md"
            @keydown.enter="searchCustomers"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="1" class="px-0">
          <v-select
            :items="data_source_enum_str"
            hide-details
            outlined
            single-line
            v-model="searchInfo.data_source"
            placeholder="資料來源"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-select>
        </v-col>
        <v-col cols="12" lg="2" class="px-1">
          <treeselect
            v-model="searchInfo.group"
            :multiple="false"
            :options="group_options"
            :searchable="searchable"
            class="font-size-input placeholder-er border border-radius-md"
            placeholder="群組"
            noChildrenText="無子群組"
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" align-self="end">
          <v-btn
            :ripple="false"
            elevation="0"
            color="#fff"
            class="
              font-weight-bolder
              btn-primary
              bg-gradient-default
              py-5
              px-6
              ms-2
            "
            @click="searchCustomers()"
          >
            {{ $t("Search") }}
            <v-icon right dark> mdi-account-search-outline </v-icon>
          </v-btn>

          <v-btn
            :ripple="false"
            elevation="0"
            color="#fff"
            class="font-weight-bolder btn-outline-secondary py-5 px-6 ms-2"
            small
            @click="reset()"
          >
            {{ $t("Reset") }}
            <v-icon right dark> mdi-close-circle-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </CustomerTableBase>
</template>

<script>
import CommonUtility from "@/util/CommonUtility.js";
import { data_source_enum_str } from "@/definitions.js";
import CustomerTableBase from "@/views/Pages/CRM/CustomerTableBase";
import CustomerGroupMixin from "@/views/Pages/CRM/CustomerGroupMixin.vue";
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "customer-search-tables",
  props: {
    auto_search: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data_source_enum_str: data_source_enum_str,
    searchInfo: {
      full_name: null,
      id_full: null,
      company: null,
      address: null,
      phone: null,
      data_source: null,
      group: null,
    },
    searchable: true,
  }),
  mixins: [CustomerGroupMixin],
  components: {
    CustomerTableBase,
    Treeselect,
  },
  mounted() {
    this.getAllGroups();
  },
  methods: {
    searchCustomers() {
      let msg = CommonUtility.validateSearchInfo(this.searchInfo);
      if (msg.length > 0) {
        alert(msg);
        return;
      }
      this.$refs.table_base.page = 1;
      this.$refs.table_base.getCustomerByCondition(this.searchInfo);
    },
    reset() {
      // set all searchInfo to null
      for (const key in this.searchInfo) {
        this.searchInfo[key] = null;
      }
      this.$refs.table_base.reset();
    },
    onUpdateCustomer(item) {
      console.log("onUpdateCustomer by search info");
      this.searchCustomers();
    },
  },
};
</script>
